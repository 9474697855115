import { DependencyList, useEffect } from 'react';

export default (
  effect: () => Promise<void>,
  cleanup: (() => Promise<void>) | undefined,
  deps: DependencyList | undefined,
) => {
  useEffect(() => {
    effect();
    return () => {
      if (typeof cleanup === 'function') {
        cleanup();
      }
    };
  }, deps);
};
