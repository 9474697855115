import { useMutation, useQuery } from 'react-query';
import { useState } from 'react';
import useApi from './useApi';
import { PaginatedResponse } from '../types';

export interface Booking {
  id: string,
  resource: string,
  status: string,
  start: string,
  end: string,
}

export interface Invitee {
  email: string;
  first_name: string;
  last_name: string;
  company: string;
  booking_id: string;
}

const useBookings = () => {
  const api = useApi();
  const [page, setPage] = useState(1);

  const bookings = useQuery<PaginatedResponse<Booking>>(['bookings', page], async () => {
    const response = await api.json<PaginatedResponse<Booking>>('GET', '/bookings', {
      params: {
        page_size: 5,
        page,
      },
    });
    return response || {} as PaginatedResponse<Booking>;
  });

  const newInvitee = useMutation(async (invitee: Invitee) => {
    await api.json('POST', `/bookings/${invitee.booking_id}/invitees`, {
      body: {
        first_name: invitee.first_name,
        last_name: invitee.last_name,
        email: invitee.email,
        company: invitee.company,
      },
    });
    return invitee;
  });

  return {
    page,
    setPage,
    bookings,
    newInvitee,
  };
};

export default useBookings;
