import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Box } from '../Box/Box';
import useOrders from '../../hooks/useOrders';
import { List } from '../List/List';
import Paginator from '../Paginator/Paginator';
import HasPermission from '../HasPermission/HasPermission';
import './styles.scss';

const Orders = () => {
  const { orders, page, setPage } = useOrders();
  const { t } = useTranslation();

  return (
    <Box
      title={t('orders')}
      className="h-1/2 text-base"
    >
      <List
        showHeaders
        loading={orders.isLoading}
        data={(orders.data?.items ?? []).map((order) => ({
          ...order,
          date: dayjs(order.date, 'YYYY-MM-DD').format('L'),
          items: order.items
            .map(({ name, quantity }) => `${quantity}x ${name}`)
            .join(', '),
        }))}
        columns={[
          ['date', t('date')],
          ['items', t('items')],
          ['total', t('total')],
        ]}
        columnsClass="grid-cols-3 2xl:grid-cols-3"
        rowClasses="order-row"
      />
      <Paginator
        next={orders.data?.next ?? null}
        previous={orders.data?.prev ?? null}
        pages={orders.data?.pages ?? 0}
        current_page={page}
        onPageClick={setPage}
      />
    </Box>
  );
};

export default () => (
  <HasPermission permissions={['orders']}>
    <Orders />
  </HasPermission>
);
