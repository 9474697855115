import React, { useCallback, useEffect, useState } from 'react';
import { User } from 'oidc-client';
import { useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import Authentication from '../services/Authentication';
import { AuthUser, PortalUser } from '../types/User';
import { AuthenticationContext } from './AuthenticationContext';

interface AuthenticationProviderProps {
  children?: React.ReactElement;
}

const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState<PortalUser | undefined>(undefined);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [expiresAt, setExpiresAt] = useState<number | undefined>(undefined);
  const auth = new Authentication();
  const history = useHistory();

  const checkUser = useCallback((authUser: User | null) => {
    if (authUser) {
      const decodedUser = jwtDecode<AuthUser>(authUser.id_token);

      if (decodedUser.user === undefined) {
        if (decodedUser.email_unverified) {
          history.push({
            pathname: '/oauth/verify/',
            state: { authUser },
          });
        } else {
          // eslint-disable-next-line no-console
          console.log('Non-portal user?', decodedUser);
          // eslint-disable-next-line no-alert
          alert('This user is not allowed!');
        }
      } else {
        setToken(authUser.access_token);
        setUser({
          ...JSON.parse(decodedUser.user || '{}'),
          group: JSON.parse(decodedUser.group || '{}'),
          organization: JSON.parse(decodedUser.organization || '{}'),
        });

        setExpiresAt(authUser.expires_at);
        setLoggedIn(true);
      }
    } else {
      setLoggedIn(false);
      setExpiresAt(undefined);
      setUser(undefined);
      setToken(undefined);
    }
  }, []);

  const load = useCallback(async () => {
    checkUser(await auth.silentLogin());
  }, []);

  useEffect(() => {
    if (expiresAt !== undefined) {
      const refreshIn = (expiresAt * 1000) - Date.now() - (60 * 10 * 1000);
      const timer = setTimeout(() => auth.silentLogin(), refreshIn);
      return () => clearTimeout(timer);
    }
    return undefined;
  }, [expiresAt]);

  const login = useCallback(() => auth.login(), []);
  const logout = useCallback(() => auth.logout(), []);

  const authCallback = useCallback(async () => {
    const result = await auth.callback();

    if (result && Object.prototype.hasOwnProperty.call(result, 'error')) {
      const { error } = result;
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(error);
      }
      history.push({
        pathname: '/',
        state: { error },
      });
      return;
    }
    if (result) {
      const { user: authUser, returnUrl } = result;
      await checkUser(authUser);
      if (returnUrl) {
        // We wait half a second to make sure the state is really set.
        // Hopefully this fixes the when you login in you are redirected back to the login page.
        setTimeout(() => history.replace(returnUrl), 500);
      }
    }
  }, [history]);

  return (
    <AuthenticationContext.Provider
      value={{
        loggedIn,
        user,
        load,
        authCallback,
        login,
        token,
        logout,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
