import { useQuery } from 'react-query';
import { useState } from 'react';
import useApi from './useApi';
import { PaginatedResponse } from '../types';

interface Order {
  id: string;
  description: string;
  total: string;
  date: string;
  items: {
    id: string;
    name: string;
    quantity: number;
    unitprice: number;
  }[]
}

const useBookings = () => {
  const api = useApi();
  const [page, setPage] = useState(1);

  const orders = useQuery<PaginatedResponse<Order>>(['orders', page], async () => {
    const response = await api.json<PaginatedResponse<Order>>('GET', '/orders', {
      params: {
        page_size: 5,
        page,
      },
    });
    return response || {} as PaginatedResponse<Order>;
  });

  return {
    page,
    setPage,
    orders,
  };
};

export default useBookings;
