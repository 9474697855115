export default {
  translation: {
    // General Translations
    submit: 'Soumettre',
    confirm: 'Confirmer',
    edit: 'Modifier',
    delete: 'Supprimer',
    resource: 'Salle',
    status: 'Statut',
    start: 'Début',
    end: 'Fin',
    cancel: 'Annuler',
    loading: 'Chargement...',
    invalid_value: 'Valeur non valide',

    // No access
    no_portal_access_title: "Pas d'accès au portail",
    no_portal_access_description: "Vous n'avez pas accès à ce portail. Veuillez contacter l'équipe Tribes si vous pensez qu'il s'agit d'une erreur.",

    // Login
    welcome_to_tribes: 'Bienvenue à Tribes',
    login_to_continue: 'Se connecter pour continuer',
    login: 'Connexion',

    // Verify email
    you_need_to_verify_your_email: 'Vous devez vérifier votre adresse e-mail. Veuillez entrer le code envoyé à votre adresse e-mail.',
    verify_code: 'Code de vérification',
    verify_email: 'Vérifier',

    // Card names
    users: 'Utilisateurs',
    groups: 'Groupes',
    bookings: 'Réservations',
    book_meetingroom: 'Réserver une salle de réunion',
    all_bookings: 'Toutes les réservations',
    orders: 'Commandes',
    invoices: 'Factures',

    // General Info Card
    logout: 'Déconnexion',
    number_of_users: "Nombre d'utilisateurs",
    number_of_groups: 'Nombre de groupes',
    your_bookings: 'Vos réservations',
    total_bookings: 'Total des réservations',

    // Users Card
    username: 'E-mail',
    first_name: 'Prénom',
    last_name: 'Nom de famille',
    title: 'Titre',
    phone_number: 'Numéro de téléphone',
    point_of_contact_type: 'Type de point de contact',
    not_a_point_of_contact: 'Pas un point de contact',
    ceo: 'PDG',
    finance: 'Finance',
    operations: 'Opérations',
    group: "Groupe d'utilisateurs",
    add_user: 'Ajouter un utilisateur',
    new_user: 'Nouvel utilisateur',
    edit_user: 'Modifier {{first name}} {{last name}}',
    delete_username: 'Etes-vous sûr de vouloir supprimer {{username}} ?',
    reset_password: 'Réinitialiser le mot de passe',
    block_access: "Bloquer la carte d'accès",

    // Groups card
    name: 'Nom',
    accessible_components: 'Composants accessibles',
    add_group: 'Ajouter un groupe',
    edit_group: 'Modifier {{groupe_name}}',
    new_group: 'Nouveau groupe',
    delete_group: 'Êtes-vous sûr de vouloir supprimer {{groupe_name}} ?',

    // Bookings card
    make_a_booking: 'Faire une réservation',
    invite: 'Inviter',
    company: 'Société',
    email: 'Adresse e-mail',

    // All Bookings card
    user: 'Utilisateur',

    // Orders card
    date: 'Date',
    items: 'Articles',
    total: 'Total',

    // Invoices card
    download: 'Télécharger',
    due_date: "Date d'échéance",
    unpaid: 'Impayé',
    paid: 'Payé',
  },
};
