import React, { useContext, useState } from 'react';
import useAsyncEffect from '../../hooks/useAsyncEffect';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import i18n from '../../i18n';
import { AuthenticationContext } from '../../state/AuthenticationContext';

interface LoadingProps {
  children: React.ReactElement;
}

export const Loading = ({ children }: LoadingProps) => {
  const [loaded, setLoaded] = useState(false);
  const { load } = useContext(AuthenticationContext);

  useAsyncEffect(
    async () => {
      await Promise.all([
        load(),
        i18n,
      ]);
      setLoaded(true);
    },
    undefined,
    [],
  );

  return loaded ? children : <LoadingSpinner fullScreen />;
};
