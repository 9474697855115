import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Box } from '../Box/Box';
import useBookings from '../../hooks/useAllBookings';
import { List } from '../List/List';
import Paginator from '../Paginator/Paginator';
import HasPermission from '../HasPermission/HasPermission';

const AllBookings = () => {
  const { bookings, page, setPage } = useBookings();
  const { t } = useTranslation();

  return (
    <Box
      title={t('all_bookings')}
      className="h-1/2 text-base"
    >
      <List
        showHeaders
        loading={bookings.isLoading}
        data={(bookings.data?.items ?? []).map((booking) => ({
          ...booking,
          startFmt: dayjs(booking.start).format('LLL'),
          endFmt: dayjs(booking.end).format('LLL'),
        }))}
        columns={[
          ['user', t('user')],
          ['resource', t('resource')],
          ['status', t('status')],
          ['startFmt', t('start')],
          ['endFmt', t('end')],
        ]}
        columnsClass="grid-cols-5 2xl:grid-cols-5"
      />
      <Paginator
        next={bookings.data?.next ?? null}
        previous={bookings.data?.prev ?? null}
        pages={bookings.data?.pages ?? 0}
        current_page={page}
        onPageClick={setPage}
      />
    </Box>
  );
};

export default () => (
  <HasPermission permissions={['all_bookings']}>
    <AllBookings />
  </HasPermission>
);
