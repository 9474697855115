import React, { ReactElement, useMemo } from 'react';
import './styles.scss';
import { ListItem } from '../ListItem/ListItem';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

interface ListProps<T> {
  data: T[];
  showHeaders?: boolean;
  columns: (keyof T)[] | [keyof T, string | ReactElement][];
  columnsClass?: string;
  loading?: boolean;
  rowClasses?: string;
  mdLabels? : boolean;
  keyColumn?: string;
}

export const List = <T extends unknown>({
  data, showHeaders, columns, loading = false, mdLabels = false, rowClasses = '',
  columnsClass = undefined, keyColumn = undefined,
}: ListProps<T>) => {
  const headers = useMemo<[keyof T, string][]>(() => {
    if (columns.length === 0) {
      return [];
    } if (columns[0] instanceof Array) {
      return columns as [(keyof T), string][];
    }
    return (columns as (keyof T)[]).map((col) => [col, '']);
  }, [columns]);

  const gridColumns = columnsClass ?? `grid-cols-${columns.length}`;

  return (
    <div>
      {showHeaders && (
        <div className={`grid ${gridColumns} mb-5 ${rowClasses}`}>
          {headers.map(([key, title]) => (
            <p key={`${key}`} className={`capitalize font-semibold table-header ${mdLabels ? 'hidden 2xl:block' : ''}`}>
              {title}
            </p>
          ))}
        </div>
      )}
      {loading && (
        <LoadingSpinner />
      )}
      {!loading && data && (
        <ul>
          {/* TODO dont use index as key */}
          {data.map((el, index) => (
            <ListItem
              key={keyColumn ? (el as Record<string, string>)[keyColumn] : index}
              data={el}
              columns={headers}
              columnsClass={gridColumns}
              mdLabels={mdLabels}
              rowClasses={rowClasses}
            />
          ))}
        </ul>
      )}
    </div>
  );
};
