import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Location } from 'history';
import { Button } from '../../components/Button/Button';
import LanguagePicker from '../../components/LanguagePicker/LanguagePicker';
import LoginError from './Error';
import Tribes from '../../components/Icons/Tribes';
import { AuthenticationContext } from '../../state/AuthenticationContext';
import { ErrorLike } from '../../hooks/useApi';

const Login = withRouter(({ location }: { location: Location<unknown> }) => {
  const [error, setError] = useState(false);
  const { login } = useContext(AuthenticationContext);
  const { t } = useTranslation();

  useEffect(() => {
    const currentError = (location.state as { error?: ErrorLike })?.error;
    if (currentError) {
      setError(true);
    }
  }, []);

  const currentError = (location.state as { error?: ErrorLike })?.error;

  return (
    <div className="w-full h-full flex justify-center">
      <div className="flex flex-col items-center">
        <div className="m-5">
          <Tribes size={150} />
        </div>
        <div className="bg-white rounded p-8">
          <h1 className="text-3xl mb-2">{t('welcome_to_tribes')}</h1>
          <p>{t('login_to_continue')}</p>
          <Button className="w-full mt-5 my-5" onClick={() => login()}>
            Login
          </Button>
          <LanguagePicker />
          {error && <LoginError errorMessage={currentError?.message ?? ''} />}
        </div>
      </div>
    </div>
  );
});

export default Login;
