import { useQuery } from 'react-query';
import { useState } from 'react';
import useApi from './useApi';
import { PaginatedResponse } from '../types';

interface Booking {
  id: string,
  resource: string,
  status: string,
  start: string,
  end: string,
  user: string,
}

const useBookings = () => {
  const api = useApi();
  const [page, setPage] = useState(1);

  const bookings = useQuery<PaginatedResponse<Booking>>(['allBookings', page], async () => {
    const response = await api.json<PaginatedResponse<Booking>>('GET', '/bookings/all', {
      params: {
        page_size: 5,
        page,
      },
    });
    return response || {} as PaginatedResponse<Booking>;
  });

  return {
    page,
    setPage,
    bookings,
  };
};

export default useBookings;
