import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useCallback } from 'react';
import useApi from './useApi';
import { Group } from '../types/Group';

const useGroups = () => {
  const api = useApi();

  const queryClient = useQueryClient();

  const sortGroups = useCallback((groups: Group[] = []) => (
    groups.sort((a, b) => (a.name < b.name ? -1 : 1))
  ), []);

  const groups = useQuery<Group[]>('groups', async () => {
    const unsortedGroups = await api.json<Group[]>('GET', '/groups');
    return sortGroups(unsortedGroups);
  });

  const newGroup = useMutation(async (group: Group) => {
    await api.json('POST', '/groups', {
      body: group,
    });
    return group;
  }, {
    onSuccess: async (group: Group) => {
      await queryClient.cancelQueries('groups');

      const loadedGroups = queryClient.getQueryData<Group[]>('groups') || [];
      queryClient.setQueryData('groups', sortGroups([...loadedGroups, group]));
    },
  });

  const editGroup = useMutation(async (group: Group) => {
    const { group_id: groupId, ...groupProperties } = group;
    await api.json('PUT', `/groups/${groupId}`, {
      body: groupProperties,
    });
    return group;
  }, {
    onSuccess: async (editedGroup: Group) => {
      await queryClient.cancelQueries('groups');

      const loadedGroups = queryClient.getQueryData<Group[]>('groups') || [];
      queryClient.setQueryData('groups', loadedGroups.map((group: Group) => (
        group.group_id === editedGroup.group_id ? editedGroup : group
      )));
    },
  });

  const deleteGroup = useMutation(async (group: Group) => {
    await api.json('DELETE', `/groups/${group.group_id}`);
    return group;
  }, {
    onSuccess: async (oldGroup: Group) => {
      await queryClient.cancelQueries('groups');

      const loadedGroups = queryClient.getQueryData<Group[]>('groups') || [];
      queryClient.setQueryData('groups', loadedGroups.filter(({ group_id }) => group_id !== oldGroup.group_id));
    },
  });

  return {
    groups,
    newGroup,
    editGroup,
    deleteGroup,
  };
};

export default useGroups;
