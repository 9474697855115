import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useForm, useWatch } from 'react-hook-form';

interface LanguagePickerProps {
  className?: string;
}

const LanguagePicker = ({ className = '' }: LanguagePickerProps) => {
  const { i18n } = useTranslation();
  const { register, control } = useForm({
    defaultValues: {
      language: i18n.language,
    },
  });

  const language = useWatch({
    control,
    name: 'language',
  });

  useEffect(() => {
    i18n.changeLanguage(language as string);
  }, [language]);

  return (
    <select ref={register} name="language" className={className}>
      <option value="en">🇬🇧 English</option>
      <option value="nl">🇳🇱 Nederlands</option>
      <option value="de">🇩🇪 Deutsch</option>
      <option value="fr">🇫🇷 Français</option>
    </select>
  );
};

export default LanguagePicker;
