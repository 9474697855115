import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { Box } from '../Box/Box';
import { Button } from '../Button/Button';
import useUsers from '../../hooks/useUsers';
import HasPermission from '../HasPermission/HasPermission';
import Statistic from '../Statistic/Statistic';
import useBookings from '../../hooks/useBookings';
import useAllBookings from '../../hooks/useAllBookings';
// import useGroups from '../../hooks/useGroups';
import LanguagePicker from '../LanguagePicker/LanguagePicker';
import { AuthenticationContext } from '../../state/AuthenticationContext';

type GeneralInfoProps = {
  headerAction: () => void;
};

const UserStatistics = () => {
  const { users } = useUsers();
  const { t } = useTranslation();
  return (
    <Statistic
      title={t('number_of_users')}
      value={users.data?.length ?? '?'}
      loading={users.isLoading}
    />
  );
};

const BookingStatistics = () => {
  const { bookings } = useBookings();
  const { t } = useTranslation();
  return (
    <Statistic
      title={t('your_bookings')}
      value={bookings.data?.total_items ?? '?'}
      loading={bookings.isLoading}
    />
  );
};

const AllBookingStatistics = () => {
  const { bookings } = useAllBookings();
  const { t } = useTranslation();
  return (
    <Statistic
      title={t('total_bookings')}
      value={bookings.data?.total_items ?? '?'}
      loading={bookings.isLoading}
    />
  );
};

const GeneralInfo = ({ headerAction }: GeneralInfoProps) => {
  const { user } = useContext(AuthenticationContext);
  const { t } = useTranslation();

  return (
    <Box
      title={user?.organization.name ?? 'Unknown'}
      headerChildren={(
        <div className="flex">
          <LanguagePicker className="mr-1.5" />
          <Button onClick={headerAction}>
            {t('logout')}
          </Button>
        </div>
      )}
    >
      <div className="flex flex-wrap justify-around">
        <HasPermission permissions={['users']}>
          <UserStatistics />
        </HasPermission>
        <HasPermission permissions={['bookings']}>
          <BookingStatistics />
        </HasPermission>
        <HasPermission permissions={['all_bookings']}>
          <AllBookingStatistics />
        </HasPermission>
      </div>
    </Box>
  );
};

export default GeneralInfo;
