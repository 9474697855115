import React from 'react';

import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Box } from '../Box/Box';
import HasPermission from '../HasPermission/HasPermission';
import useInvoices from '../../hooks/useInvoices';
import { List } from '../List/List';
import Paginator from '../Paginator/Paginator';

const Invoices = () => {
  const {
    invoices, download, page, setPage,
  } = useInvoices();
  const { t } = useTranslation();
  return (
    <Box
      title={t('invoices')}
      className="h-1/2 text-base"
    >
      <List
        showHeaders
        loading={invoices.isLoading}
        data={(invoices.data?.items ?? []).map((invoice) => ({
          ...invoice,
          download: () => download(invoice),
          due_date: dayjs(invoice.due_date, 'YYYY-MM-DD').format('L'),
        }))}
        columns={[
          ['due_date', t('due_date')],
          ['status', t('status')],
          ['download', t('download')],
        ]}
        columnsClass="grid-cols-3 2xl:grid-cols-3"
        keyColumn="id"
      />
      <Paginator
        next={invoices.data?.next ?? null}
        previous={invoices.data?.prev ?? null}
        pages={invoices.data?.pages ?? 0}
        current_page={page}
        onPageClick={setPage}
      />
    </Box>
  );
};

export default () => (
  <HasPermission permissions={['invoices']}>
    <Invoices />
  </HasPermission>
);
