export default {
  translation: {
    // General Translations
    submit: 'Opslaan',
    confirm: 'Bevestigen',
    edit: 'Bewerken',
    delete: 'Verwijderen',
    resource: 'Ruimte',
    status: 'Status',
    start: 'Start',
    end: 'Eind',
    cancel: 'Annuleren',
    loading: 'Laden...',
    invalid_value: 'Ongeldige waarde',

    // No access
    no_portal_access_title: 'Geen portaltoegang',
    no_portal_access_description: 'U heeft geen toegang tot deze portal. Neem contact op met het Tribes-team als u denkt dat dit een fout is.',

    // Login
    welcome_to_tribes: 'Welkom bij Tribes',
    login_to_continue: 'Login om door te gaan',
    login: 'Inloggen',

    // Verify email
    you_need_to_verify_your_email: 'Uw e-mailadres is nog niet geverifieerd. Voer a.u.b. de code in die u per e-mail ontvangt.',
    verify_code: 'Verificatiecode',
    verify_email: 'Verifiëren',

    // Card names
    users: 'Gebruikers',
    groups: 'Groepen',
    bookings: 'Boekingen',
    book_meetingroom: 'Boeken Vergaderruimte',
    all_bookings: 'Alle Boekingen',
    orders: 'Bestellingen',
    invoices: 'Facturen',

    // General Info Card
    logout: 'Uitloggen',
    number_of_users: 'Aantal Gebruikers',
    number_of_groups: 'Aantal Groepen',
    your_bookings: 'Jouw Boekingen',
    total_bookings: 'Alle Boekingen',

    // Users Card
    username: 'Email',
    first_name: 'Voornaam',
    last_name: 'Achternaam',
    title: 'Titel',
    phone_number: 'Telefoon\u00ADnummer',
    point_of_contact_type: 'Aanspreekpunt',
    not_a_point_of_contact: 'Geen aanspreekpunt',
    ceo: 'CEO',
    finance: 'Financiën',
    operations: 'Operations',
    group: 'Groep',
    add_user: 'Gebruiker toevoegen',
    new_user: 'Nieuwe Gebruiker',
    edit_user: 'Bewerk {{first_name}} {{last_name}}',
    delete_username: 'Weet je zeker dat je {{username}} wilt verwijderen?',
    reset_password: 'Reset wachtwoord',
    block_access: 'Blokkeer toegangspas',

    // Groups card
    name: 'Naam',
    accessible_components: 'Beschikbare Componenten',
    add_group: 'Groep toevoegen',
    edit_group: 'Bewerk {{group_name}}',
    new_group: 'Nieuwe Groep',
    delete_group: 'Weet je zeker dat je {{group_name}} wilt verwijderen?',

    // Bookings card
    make_a_booking: 'Maak een reservering',
    invite: 'Uitnodigen',
    company: 'Bedrijf',
    email: 'E-mailadres',

    // All Bookings card
    user: 'Gebruiker',

    // Orders card
    date: 'Datum',
    items: 'Artikelen',
    total: 'Totaal',

    // Invoices card
    download: 'Download',
    due_date: 'Vervaldatum',
    unpaid: 'Onbetaald',
    paid: 'Betaald',
  },
};
