export default {
  translation: {
    // General Translations
    submit: 'Senden',
    confirm: 'Bestätigen Sie',
    edit: 'Bearbeiten',
    delete: 'Löschen',
    resource: 'Zimmer',
    status: 'Status',
    start: 'Beginn',
    end: 'Ende',
    cancel: 'Abbrechen',
    loading: 'Laden...',
    invalid_value: 'Ungültiger Wert',

    // No access
    no_portal_access_title: 'Kein Portalzugang',
    no_portal_access_description: 'Sie haben keinen Zugang zu diesem Portal. Bitte kontaktieren Sie das Tribes-Team, wenn Sie der Meinung sind, dass dies ein Fehler ist.',

    // Login
    welcome_to_tribes: 'Willkommen bei Tribes',
    login_to_continue: 'Anmelden um fortzufahren',
    login: 'Anmeldung',

    // Verify email
    you_need_to_verify_your_email: 'Sie müssen Ihre E-Mail-Adresse verifizieren. Bitte geben Sie den an Ihrer E-Mail-Adresse gesendeten Code ein.',
    verify_code: 'Verifizierungs-Code',
    verify_email: 'Überprüfen Sie',

    // Card names
    users: 'Benutzer',
    groups: 'Gruppen',
    bookings: 'Buchungen',
    book_meetingroom: 'Meetingraum buchen',
    all_bookings: 'Alle Buchungen',
    orders: 'Bestellungen',
    invoices: 'Rechnungen',

    // General Info Card
    logout: 'Abmelden',
    number_of_users: 'Anzahl der Benutzer',
    number_of_groups: 'Anzahl der Gruppen',
    your_bookings: 'Ihre Buchungen',
    total_bookings: 'Total Buchungen',

    // Users Card
    username: 'E-Mail',
    first_name: 'Vorname',
    last_name: 'Nachname',
    title: 'Titel',
    phone_number: 'Telefonnummer',
    point_of_contact_type: 'Kontaktperson Typ',
    not_a_point_of_contact: 'Keine Kontaktperson',
    ceo: 'GESCHÄFTSFÜHRER',
    finance: 'Finanzen',
    operations: 'Betrieb',
    group: 'Benutzergruppe',
    add_user: 'Benutzer hinzufügen',
    new_user: 'Neuer Benutzer',
    edit_user: 'Editieren {{first_name}} {{last_name}}',
    delete_username: 'Sind Sie sicher, dass Sie {{username}} löschen möchten?',
    reset_password: 'Passwort zurücksetzen',
    block_access: 'Zugangskarte sperren',

    // Groups card
    name: 'Name',
    accessible_components: 'Zugängliche Komponenten',
    add_group: 'Gruppe hinzufügen',
    edit_group: 'Bearbeiten {{group_name}}',
    new_group: 'Neue Gruppe',
    delete_group: 'Sind Sie sicher, dass Sie {{group_name}} löschen möchten?',

    // Bookings card
    make_a_booking: 'Eine Buchung vornehmen',
    invite: 'Einladen',
    company: 'Unternehmen',
    email: 'E-Mail Adresse',

    // All Bookings card
    user: 'Benutzer',

    // Orders card
    date: 'Datum',
    items: 'Artikel',
    total: 'Gesamt',

    // Invoices card
    download: 'Herunterladen',
    due_date: 'Fälligkeitsdatum',
    unpaid: 'Unbezahlt',
    paid: 'Bezahlt',
  },
};
