import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useWindowSize from '../../hooks/useWindowSize';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import GeneralInfo from '../../components/GeneralInfo/GeneralInfo';
import Users from '../../components/Users/Users';
import Bookings from '../../components/Bookings/Bookings';
import AllBookings from '../../components/AllBookings/AllBookings';
import Orders from '../../components/Orders/Orders';
import Invoices from '../../components/Invoices/Invoices';
import { AuthenticationContext } from '../../state/AuthenticationContext';
import { Box } from '../../components/Box/Box';

const NoPortalAccess = () => {
  const { t } = useTranslation();

  return (
    <Box title={t('no_portal_access_title')}>
      <p>{t('no_portal_access_description')}</p>
    </Box>
  );
};

const Home = () => {
  const { user, logout } = useContext(AuthenticationContext);
  const [width] = useWindowSize();

  const userHasNoPermissions = useMemo(() => Object.keys(user?.group ?? {}).length === 0, [user]);

  return (
    <div className="App flex-none bg-background min-h-screen h-full w-screen md:flex">
      <Sidebar type={width < 768 ? 'horizontal' : 'vertical'} />

      <main className="masonry-single lg:masonry h-full w-full pt-5 px-5">
        <GeneralInfo headerAction={() => logout()} />
        <Users />
        <Bookings
          widgetUrlOverride={process.env.REACT_APP_WIDGET_URL_OVERRIDE}
          apiOverride={process.env.REACT_APP_WIDGET_API_OVERRIDE}
          business={process.env.REACT_APP_WIDGET_BUSINESS ?? ''}
          pageId={process.env.REACT_APP_WIDGET_PAGEID ?? ''}
          contactId={user?.contact_id ?? ''}
        />
        <AllBookings />
        <Orders />
        <Invoices />
        {userHasNoPermissions && <NoPortalAccess />}
      </main>
    </div>
  );
};

export default Home;
