import React, { useState, useEffect } from 'react';

import './styles.scss';

const LoginError = ({ errorMessage } : { errorMessage: string }) => {
  const [errorCode, setErrorCode] = useState('');
  const [errorRequestId, setErrorRequestId] = useState('');

  const renderErrorDetails = errorRequestId && errorCode;

  useEffect(() => {
    const requestIdPattern = /Request ID: [0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/gm;
    const errorCodePattern = / Error Code: [A-Za-z]+/gm;
    const requestIdMatch = errorMessage.match(requestIdPattern);
    const errorCodeMatch = errorMessage.match(errorCodePattern);
    setErrorRequestId(requestIdMatch != null ? requestIdMatch[0] : '');
    setErrorCode(errorCodeMatch != null ? errorCodeMatch[0] : '');
  }, [errorMessage]);

  return (
    <>
      <p className="error">
        An unexpected error occured.
      </p>
      {renderErrorDetails && (
      <>
        <p>Please contact your administrator with this information:</p>
        <p>{errorCode}</p>
        <p>{errorRequestId}</p>
      </>
      )}
    </>
  );
};

export default LoginError;
