import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { User } from 'oidc-client';

import { History } from 'history';
import Tribes from '../../components/Icons/Tribes';
import { Button } from '../../components/Button/Button';
import LanguagePicker from '../../components/LanguagePicker/LanguagePicker';

type AuthUserState = {
  authUser?: User;
};

const sendEmail = async (user: User, history: History<unknown>) => {
  const url = new URL(process.env.REACT_APP_API as string, window.location.href);
  url.pathname += '/users/verification';
  const response = await fetch(url.toString(), {
    method: 'POST',
    headers: {
      authorization: `Bearer ${user.access_token}`,
    },
  });
  if (response.status === 202) {
    // Already verified, skip
    history.replace('/');
  }
};

const sendEmailDebounced = (() => {
  // This function ensures we're not calling our endpoint twice concurrently
  // Calling it twice in a row is fine
  const map = new Map<string, Promise<unknown>>();
  return (user: User, history: History<unknown>) : Promise<unknown> => {
    const email = user.profile['cognito:username'];
    const running = map.get(email);
    if (running) {
      return running;
    }
    const promise = sendEmail(user, history);
    map.set(email, promise);
    promise.then(() => map.delete(email));
    promise.catch(() => map.delete(email));
    return promise;
  };
})();

const VerifyEmail = withRouter(({ history, location: { state } }) => {
  const { t } = useTranslation();
  const [verifyCode, setVerifyCode] = useState('');
  const verify = async (user: User, code: string) => {
    const cleanCode = code.replace(/[^0-9]/g, '').substr(0, 6);
    if (cleanCode.length !== 6) {
      return;
    }
    const url = new URL(process.env.REACT_APP_API as string, window.location.href);
    url.pathname += `/users/verify/${cleanCode}`;
    const response = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        authorization: `Bearer ${user.access_token}`,
      },
    });
    if (response.status === 202) {
      // Verified, skip
      history.replace('/');
    }
  };

  const { authUser = null } = state as AuthUserState;
  useEffect(() => {
    if (authUser) {
      sendEmailDebounced(authUser, history)
        .catch((error) => {
          console.error(error); // eslint-disable-line no-console
        });
    }
  }, [authUser]);

  return (
    <div className="w-full h-full flex justify-center">
      <div className="flex flex-col items-center">
        <div className="m-5">
          <Tribes size={150} />
        </div>
        <div className="bg-white rounded p-8">
          <h1 className="text-3xl mb-2">{t('welcome_to_tribes')}</h1>
          <p className="mb-2">{t('you_need_to_verify_your_email')}</p>
          <label htmlFor="verify-code">{t('verify_code')}</label>
          <input id="verify-code" type="text" inputMode="numeric" onChange={(event) => setVerifyCode(event.currentTarget.value)} />
          <Button className="w-full mt-5 my-5" onClick={() => authUser && verify(authUser, verifyCode)}>
            {t('verify_email')}
          </Button>
          <LanguagePicker />
        </div>
      </div>
    </div>
  );
});

export default VerifyEmail;
