import { useQuery } from 'react-query';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useApi from './useApi';
import { PaginatedResponse } from '../types';

interface Invoice {
  id: string;
  number: string;
  due_date: string;
  amount_due: number | null;
  status?: string;
}

const useBookings = () => {
  const api = useApi();
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  const invoices = useQuery<PaginatedResponse<Invoice>>(['invoices', page], async () => {
    const response = await api.json<PaginatedResponse<Invoice>>('GET', '/invoices', {
      params: {
        page_size: 5,
        page,
      },
    });
    if (response) {
      response.items = response.items.map((invoice: Invoice) => {
        const status = (invoice.amount_due ?? Infinity) === 0 ? t('paid') : t('unpaid');
        return {
          ...invoice,
          status,
        };
      });
    }
    return response || {} as PaginatedResponse<Invoice>;
  });

  const download = useCallback(async (invoice: Invoice) => {
    const response = await api.blob('GET', `/invoices/${invoice.id}/pdf/${invoice.number}`);

    const link = document.createElement('a');

    link.href = URL.createObjectURL(response);
    link.download = `${invoice.number}.pdf`;

    document.body.append(link);
    link.click();
    link.remove();

    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  }, []);

  return {
    page,
    setPage,
    invoices,
    download,
  };
};

export default useBookings;
