import React from 'react';

type Props = {
  size?: number;
  fill?: string;
  onClick?: () => void;
};

export const Close = ({ fill = '#000', size = 26, onClick }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 26 26"
    onClick={onClick}
  >
    <g fill={fill} fillRule="nonzero">
      <path d="M18.284 7.029l.679.679-5.29 5.287 5.29 5.289-.68.679-5.288-5.29-5.287 5.29-.68-.68 5.29-5.288-5.29-5.287.68-.68 5.287 5.29z" />
      <path d="M13 .555C6.127.555.555 6.127.555 13S6.127 25.445 13 25.445 25.445 19.873 25.445 13 19.873.555 13 .555zm0 .96c6.343 0 11.485 5.142 11.485 11.485 0 6.343-5.142 11.485-11.485 11.485-6.343 0-11.485-5.142-11.485-11.485C1.515 6.657 6.657 1.515 13 1.515z" />
    </g>
  </svg>
);
