import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import AuthenticationProvider from './state/AuthenticationProvider';
import AuthCallback from './pages/AuthCallback/AuthCallback';
import { Loading } from './components/Loading/Loading';
import ComponentComposer from './components/ComponentComposer/ComponentComposer';
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import VerifyEmail from './pages/VerifyEmail/VerifyEmail';
import { AuthenticationContext } from './state/AuthenticationContext';

const NonCallbackRoutes = () => {
  const { loggedIn } = useContext(AuthenticationContext);
  return (
    <Route>
      <Loading>
        {loggedIn ? (
          <Switch>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        ) : (
          <Login />
        )}
      </Loading>
    </Route>
  );
};

const queryClient = new QueryClient();
const App = () => (
  <Router>
    <ComponentComposer
      components={[
        // eslint-disable-next-line react/jsx-key
        <QueryClientProvider client={queryClient} />,

        // eslint-disable-next-line react/jsx-key
        <AuthenticationProvider />,
      ]}
    >
      <Switch>
        <Route exact strict path="/oauth/callback/">
          <AuthCallback />
        </Route>
        <Route exact strict path="/oauth/verify/">
          <VerifyEmail />
        </Route>
        <NonCallbackRoutes />
      </Switch>
    </ComponentComposer>
  </Router>
);

export default App;
