import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useCallback } from 'react';
import useApi from './useApi';

export interface User {
  first_name: string;
  last_name: string;
  account_id: string;
  contact_id?: string;
  group_id: string;
  cognito_username: string;
  phone_number: string;
  title: string;
}

const useUsers = () => {
  const api = useApi();

  const sortUsers = useCallback((users: User[] = []) => (
    users.sort((a, b) => (a.cognito_username < b.cognito_username ? -1 : 1))
  ), []);

  const users = useQuery('users', async () => {
    const unsortedUsers = await api.json<User[]>('GET', '/users');

    return sortUsers(unsortedUsers);
  });

  const queryClient = useQueryClient();

  const newUser = useMutation(async (user: User) => {
    await api.json('POST', '/users', {
      body: {
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.cognito_username,
        group_id: user.group_id,
        phone_number: user.phone_number,
        title: user.title,
      },
    });
    return user;
  }, {
    onSuccess: async (user: User) => {
      await queryClient.cancelQueries('users');

      const loadedUsers = queryClient.getQueryData<User[]>('users') || [];
      queryClient.setQueryData('users', sortUsers([...loadedUsers, user]));
    },
  });

  const editUser = useMutation(async (user: User) => {
    await api.json('PUT', `/users/${user.cognito_username}`, {
      body: {
        first_name: user.first_name,
        last_name: user.last_name,
        group_id: user.group_id,
        phone_number: user.phone_number,
        title: user.title,
      },
    });
    return user;
  }, {
    onSuccess: async (editedUser: User) => {
      await queryClient.cancelQueries('users');

      const loadedUsers = queryClient.getQueryData<User[]>('users') || [];
      queryClient.setQueryData('users', loadedUsers.map((user: User) => (
        user.cognito_username === editedUser.cognito_username ? editedUser : user
      )));
    },
  });

  const blockUser = async (user: User) => {
    await api.json('PUT', `/users/${user.cognito_username}/access`, {
      body: {
        has_access: false,
      },
    });
  };

  const resetUser = async (user: User) => {
    await api.json('POST', `/users/${user.cognito_username}/password`, {
      body: {},
    });
  };

  const deleteUser = useMutation(async (user: User) => {
    await api.json('DELETE', `/users/${user.cognito_username}`);
    return user;
  }, {
    onSuccess: async (oldUser: User) => {
      await queryClient.cancelQueries('users');

      const loadedUsers = queryClient.getQueryData<User[]>('users') || [];
      queryClient.setQueryData('users', loadedUsers.filter(({ cognito_username }) => cognito_username !== oldUser.cognito_username));
    },
  });

  return {
    users, newUser, editUser, deleteUser, blockUser, resetUser,
  };
};

export default useUsers;
