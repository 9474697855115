import React from 'react';

import Tribes from '../Icons/Tribes';

type Props = {
  type?: 'horizontal' | 'vertical';
};

export const Sidebar = ({ type = 'vertical' }: Props) => (type === 'horizontal' ? (
  <nav className="flex w-full p-4">
    <Tribes />
  </nav>
) : (
  <nav className="flex justify-center w-28 p-4">
    <Tribes />
  </nav>
));
