import React, { ReactNode, useContext, useMemo } from 'react';
import { Group } from '../../types/Group';
import { AuthenticationContext } from '../../state/AuthenticationContext';

interface HasPermissionProps {
  permissions: (keyof Group)[];
  children?: ReactNode;
}

const HasPermission = ({ permissions, children }: HasPermissionProps) => {
  const { user } = useContext(AuthenticationContext);

  const hasPermission = useMemo(() => (
    user && permissions.some((permission) => user.group[permission])
  ), [user, permissions]);

  return (
    <>
      {hasPermission && children}
    </>
  );
};

export default HasPermission;
