import React from 'react';

interface PaginatorProps {
  previous: number | null;
  next: number | null;
  pages: number;
  current_page: number;
  onPageClick: (page: number) => void;
  className?: string;
}

const GAP = Object.freeze({});

type NumberOfGap = number | typeof GAP;

function generatePages(
  maxPage: number, currentPage: number,
  outerWindow = 1, innerWindow = 3,
): NumberOfGap[] {
  const pages = Array.from(Array(maxPage), (_, index) => index + 1);

  const numbers: NumberOfGap[] = pages.map((page) => {
    if (page <= outerWindow || page > maxPage - outerWindow) {
      return page;
    }

    if (page > currentPage - innerWindow && page < currentPage + innerWindow) {
      return page;
    }

    // Single gap bottom
    if (page === outerWindow + 1 && page === currentPage - innerWindow) {
      return page;
    }

    // Single gap top
    if (page === maxPage - outerWindow && page === currentPage + innerWindow) {
      return page;
    }

    return GAP;
  });

  return numbers.reduce((squashedNumbers: NumberOfGap[], number: NumberOfGap) => {
    if (number === GAP && squashedNumbers.length > 0
      && squashedNumbers[squashedNumbers.length - 1] === GAP) {
      return squashedNumbers;
    }
    return squashedNumbers.concat([number]);
  }, []);
}

export default function Paginator({
  previous,
  next,
  pages,
  current_page,
  onPageClick,
  className,
}: PaginatorProps) {
  const pageNumbers = generatePages(pages, current_page);

  return (
    <div className={`flex flex-row w-full justify-center ${className ?? ''}`}>
      {previous && (
        <button
          type="button"
          className="m-0.5 p-1 bg-gray-200 rounded"
          onClick={() => onPageClick(previous)}
        >
          ←
        </button>
      )}
      {
        pages > 1
        && pageNumbers
          .map((pageNumber) => (
            pageNumber === GAP
              ? (
                <button
                  type="button"
                  className="m-0.5 p-2 bg-white rounded"
                  disabled
                >
                  …
                </button>
              )
              : (
                <button
                  type="button"
                  className={`m-0.5 p-2 bg-gray-${pageNumber === current_page ? '400' : '200'} rounded`}
                  disabled={pageNumber === current_page}
                  onClick={() => onPageClick(pageNumber as number)}
                >
                  {pageNumber}
                </button>
              )
          ))
      }
      {
        next && (
          <button
            type="button"
            className="m-0.5 p-1 bg-gray-200 rounded"
            onClick={() => onPageClick(next)}
          >
            →
          </button>
        )
      }
    </div>
  );
}
