import React from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';

interface LoadingSpinnerProps {
  fullScreen?: boolean;
}

const LoadingSpinner = ({ fullScreen }: LoadingSpinnerProps) => {
  const { t } = useTranslation();
  return (
    <div className={`${fullScreen ?? false ? 'loading-state' : ''} w-full h-full flex flex-col justify-center items-center`}>
      <div className="spinner" />
      {(fullScreen ?? false) && (
        <p className="pt-2">{t('loading')}</p>
      )}
    </div>
  );
};

export default LoadingSpinner;
