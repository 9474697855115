import React from 'react';
import { PortalUser } from '../types/User';

interface AuthenticationContextProps {
  loggedIn: boolean;
  user: PortalUser | undefined;
  load: () => Promise<void>;
  login: () => void;
  logout: () => void;
  authCallback: () => Promise<void>;
  token: string | undefined;
}

export const AuthenticationContext = React.createContext({} as AuthenticationContextProps);
