import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputField } from '../FormComponents/Input';
import { Button } from '../Button/Button';
import { Invitee } from '../../hooks/useBookings';

interface InviteeFormProps {
  onSubmit: (invitee: Invitee) => void;
  invitee?: Invitee;
  edit: boolean;
  loading?: boolean;
}

export const InviteeForm = ({
  onSubmit, invitee, edit, loading = false,
}: InviteeFormProps) => {
  const { register, handleSubmit, errors } = useForm<Invitee>({
    defaultValues: invitee,
  });

  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-3 gap-4">
        <InputField<Invitee>
          label={t('email')}
          type="email"
          register={register}
          name="email"
          required
          disabled={edit}
        >
          {errors.email && <p className="text-red-500">{t('invalid_value')}</p>}
        </InputField>

        <InputField<Invitee>
          label={t('first_name')}
          type="text"
          register={register}
          name="first_name"
          required
        >
          {errors.first_name && <p className="text-red-500">{t('invalid_value')}</p>}
        </InputField>

        <InputField<Invitee>
          label={t('last_name')}
          type="text"
          register={register}
          name="last_name"
          required
        >
          {errors.last_name && <p className="text-red-500">{t('invalid_value')}</p>}
        </InputField>

        <InputField<Invitee>
          label={t('company')}
          type="text"
          register={register}
          name="company"
          required
        >
          {errors.company && <p className="text-red-500">{t('invalid_value')}</p>}
        </InputField>
      </div>

      <div className="flex">
        <Button type="submit" disabled={loading}>{loading ? t('loading') : t('submit')}</Button>
        <div className="flex-grow" />
      </div>
    </form>
  );
};
