import { UserManager } from 'oidc-client';

export default class Authentication {
  private userManager: UserManager;

  private readonly client_id: string;

  private readonly authority: string;

  private readonly redirectUrl: URL;

  constructor() {
    this.redirectUrl = new URL(window.location.origin);
    this.redirectUrl.pathname = '/oauth/callback/';
    this.client_id = process.env.REACT_APP_CLIENT_ID as string;
    this.authority = process.env.REACT_APP_PROVIDER_URL as string;

    this.userManager = new UserManager({
      authority: this.authority,
      client_id: this.client_id,
      redirect_uri: this.redirectUrl.href,
      automaticSilentRenew: false,
      loadUserInfo: false,
      response_type: 'code',
      scope: 'openid profile',
      silentRequestTimeout: 5000,
      mergeClaims: true,
    });
  }

  async login() {
    sessionStorage.setItem('returnUrl', window.location.pathname);
    sessionStorage.setItem('returnType', 'login');

    await this.userManager.signinRedirect();
  }

  async logout() {
    sessionStorage.setItem('returnUrl', window.location.pathname);
    sessionStorage.setItem('returnType', 'logout');

    const logoutUrl = new URL(await this.userManager.metadataService.getAuthorizationEndpoint());
    const redirectUrl = new URL(this.redirectUrl.href);
    redirectUrl.searchParams.append('logout', 'true');

    logoutUrl.pathname = '/logout';
    logoutUrl.searchParams.append('client_id', this.client_id);
    logoutUrl.searchParams.append('logout_uri', redirectUrl.href);

    window.location.href = logoutUrl.toString();
  }

  async silentLogin() {
    try {
      const user = await this.userManager.signinSilent();
      return user && user.expires_in > 0 ? user : null;
    } catch (e) {
      return null;
    }
  }

  async refresh() {
    return this.login();
  }

  async callback() {
    const returnUrl = sessionStorage.getItem('returnUrl');
    const returnType = sessionStorage.getItem('returnType');

    switch (returnType) {
      case 'login':
        try {
          return {
            user: await this.userManager.signinRedirectCallback(),
            returnUrl,
          };
        } catch (error) {
          return {
            error,
            user: null,
            returnUrl: '/',
          };
        }
      case 'logout':
        sessionStorage.clear();
        await this.userManager.signoutRedirectCallback();
        return { user: null, returnUrl };
      case 'silent':
        await this.userManager.signinSilentCallback();
        return null;
      default:
        return {
          user: null,
          returnUrl: '/',
        };
    }
  }
}
