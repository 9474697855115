import React, { useContext, useEffect } from 'react';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { AuthenticationContext } from '../../state/AuthenticationContext';

const AuthCallback = () => {
  const { authCallback } = useContext(AuthenticationContext);

  useEffect(() => {
    authCallback();
  }, []);

  return <LoadingSpinner fullScreen />;
};

export default AuthCallback;
