import React, { ReactNode, ButtonHTMLAttributes } from 'react';

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  disabled?: boolean;
  className?: string;
}

export const Button = ({
  disabled, children, className, ...props
}: Props) => {
  const color = disabled ? 'bg-gray-300' : 'bg-primary text-white';
  return (
    <button
      className={`${color} px-4 py-2 text-sm lg:text-base rounded-full transition-colors ease-in-out duration-200 hover:bg-primaryDark ${className ?? ''}`}
      disabled={disabled}
      type="button"
      {...props /* eslint-disable-line react/jsx-props-no-spreading */}
    >
      {children}
    </button>
  );
};
