import { createRef, useEffect } from 'react';

export interface BookerReservationProperties {
  Id: string
  B25__Price__c?: number
  B25__ResourceName__c?: string
  B25__Start_GMT__c?: string
  B25__End_GMT__c?: string
  Meeting_Room_Price_Website__c?: number
  Meeting_Room_VAT_Website__c?: number
  B25LP__Quantity_For_External_Client__c: number
  CreatedDate: string
}

export interface BookerReservation {
  id: string
  properties: BookerReservationProperties
}

export interface BookerWidgetProps {
  page: string
  business: string
  api?: string
  resource?: string
  reservation?: string
  contact?: string
  lead?: string
  className?: string
  onLoad?: () => void
  onToggle?: () => void
  onReservation?: (reservation: BookerReservation) => void
}

export default function BookerWidget({
  page,
  business,
  resource,
  reservation,
  contact,
  lead,
  api,
  onReservation,
  onLoad,
  onToggle,
  className,
}: BookerWidgetProps): JSX.Element {
  const widgetRef = createRef<EventTarget>();

  useEffect(() => {
    const widget = widgetRef.current;
    if (widget != null) {
      const onLoadHandler = function onLoadHandler(): void {
        if (onLoad != null) {
          onLoad();
        }
      };
      const onReservationHandler = function onReservationHandler(event: CustomEvent): void {
        if (onReservation != null) {
          onReservation(event.detail);
        }
      };
      const onToggleHandler = function onToggleHandler(): void {
        if (onToggle != null) {
          onToggle();
        }
      };
      widget.addEventListener('load', onLoadHandler);
      widget.addEventListener('reservation', onReservationHandler as EventListener);
      widget.addEventListener('toggle', onToggleHandler as EventListener);
      return () => {
        widget.removeEventListener('load', onLoadHandler);
        widget.removeEventListener('reservation', onReservationHandler as EventListener);
        widget.removeEventListener('toggle', onToggleHandler as EventListener);
      };
    }
    return undefined;
  }, [widgetRef]);

  // noinspection HtmlUnknownAttribute
  return (
    <booker-widget
      ref={widgetRef}
      page={page}
      business={business}
      resource={resource}
      reservation={reservation}
      contact={contact}
      lead={lead}
      api={api}
      lang="en"
      class={className}
    />
  );
}
