import React from 'react';

type Props = {
  fill?: string;
  size?: number;
};

export default function Tribes({ size = 98, fill = 'black' }: Props) {
  return (
    <svg
      width={size}
      height={Math.round((size * 20.0) / 98)}
      viewBox="0 0 98 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.6517 6.48197V4.40584H25.8501V6.48197H28.652V15.6667L30.8517 13.7206V6.48197H33.6517ZM44.6674 13.7206H47.2736L45.1792 10.2056C46.2913 9.77888 46.9066 8.83363 46.9066 7.55542C46.9066 5.62664 45.5574 4.40201 43.2542 4.40201H39.7749V13.7206H41.9727V10.6476H42.955L44.6674 13.7206ZM41.9709 8.86042V6.42839H43.3859C44.2365 6.42839 44.6542 6.89527 44.6542 7.6664C44.6542 8.43753 44.2101 8.86424 43.3972 8.86424L41.9709 8.86042ZM53.7505 13.6709H55.9577V4.35226H53.7524L53.7505 13.6709ZM68.5653 8.65567C68.9227 8.49701 69.2256 8.23377 69.4354 7.89948C69.6451 7.5652 69.7523 7.17499 69.7433 6.77855C69.7433 5.48887 68.6142 4.35609 66.9546 4.35609H63.0029V13.6747H66.947C69.2258 13.6747 70.2325 12.3965 70.2325 10.9595C70.2466 10.4478 70.0893 9.94638 69.7864 9.53751C69.4835 9.12865 69.0529 8.83659 68.5653 8.70925V8.65567ZM65.2008 8.04336V6.20642H66.518C67.1465 6.20642 67.5266 6.60634 67.5266 7.12489C67.5266 7.67215 67.1333 8.04336 66.5312 8.04336H65.2008ZM65.2008 11.8244V9.70234H66.7457C67.4645 9.70234 67.9105 10.1023 67.9105 10.7682C67.9105 11.3939 67.504 11.832 66.7457 11.832L65.2008 11.8244ZM76.9315 13.6747H83.6737V11.5986H79.1369V9.89369H82.4355V8.02997H79.1369V6.43413H83.6191V4.35609H76.9371L76.9315 13.6747ZM89.8627 11.346C90.3858 13.3686 92.1923 13.8622 93.7899 13.8622C96.0273 13.8622 97.3501 12.9055 97.3501 10.992C97.3501 8.98096 95.8579 8.39544 94.5087 8.12181C93.2254 7.85584 92.3485 7.73912 92.3485 6.95076C92.3485 6.45709 92.7022 6.12606 93.5396 6.12606C94.456 6.12606 94.981 6.49727 95.2031 7.17655L97.2974 6.60251C96.7875 4.93778 95.4514 4.16666 93.5791 4.16666C91.5243 4.16666 90.1111 5.11191 90.1111 7.01582C90.1111 8.98479 91.656 9.57032 92.8998 9.87647C94.0289 10.1578 95.124 10.1042 95.124 10.9959C95.124 11.5948 94.6141 11.8875 93.7767 11.8875C92.7813 11.8875 92.1396 11.4742 91.9571 10.7701L89.8627 11.346Z"
        fill={fill}
      />
      <path
        d="M8.92617 20C11.5538 19.8018 17.8523 15.2099 17.8523 8.38788C17.8523 5.16202 16.9576 2.22529 15.4944 0.0259894C14.4461 0.374219 13.3474 0.544392 12.2434 0.529522C11.1157 0.545292 9.99363 0.366182 8.92617 0C7.85872 0.366182 6.73667 0.545292 5.60894 0.529522C4.50489 0.543868 3.40618 0.373146 2.35792 0.0243647C0.894718 2.22529 0 5.16202 0 8.38788C0 15.2099 6.30501 19.8018 8.92617 20Z"
        fill={fill}
      />
    </svg>
  );
}
