import { useCallback, useContext } from 'react';
import { AuthenticationContext } from '../state/AuthenticationContext';

export interface ErrorLike {
  message: string
}

type ObjectType = { [p: string]: unknown };

interface CallOptions {
  params?: ObjectType;
  body?: unknown;
  headers?: ObjectType;
}

export default () => {
  const { token } = useContext(AuthenticationContext);

  const call = useCallback(async <E extends unknown = ErrorLike>(
    method: string,
    endpoint: string,
    { params, body, headers }: CallOptions = {
      params: {},
      body: undefined,
      headers: {},
    },
  ): Promise<Response | undefined> => {
    if (token === null) {
      throw new Error('Token is null');
    }

    const url = new URL(process.env.REACT_APP_API as string, window.location.href);
    url.pathname += endpoint;

    Object
      .entries((params || {}) as ObjectType)
      .forEach(([key, value]) => {
        url.searchParams.append(key, value as string);
      });

    const init: RequestInit = {
      method,
      cache: 'no-store',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        ...(headers || {}),
      },
    };

    if (['PUT', 'POST'].includes(method)) {
      init.body = body === undefined ? '' : JSON.stringify(body);
    }

    const response = await fetch(url.href, init);
    if (!response.ok) {
      const errorObject = await response.json() as E;
      throw new Error((errorObject as ErrorLike).message);
    }

    if (response.status === 204) {
      return undefined;
    }

    return response;
  }, [token]);

  const json = useCallback(async <T extends unknown, E extends unknown = ErrorLike>
  (method: string, endpoint: string, options?: CallOptions): Promise<T | undefined> => {
    const response = await call<E>(method, endpoint, options);
    if (response === undefined) {
      return undefined;
    }

    return await response.json() as T;
  }, [token]);

  const blob = useCallback(async <E extends unknown = ErrorLike>
  (method: string, endpoint: string, options?: CallOptions): Promise<Blob | undefined> => {
    const response = await call<E>(method, endpoint, options);
    if (response === undefined) {
      return undefined;
    }

    return response.blob();
  }, [token]);

  return { json, blob };
};
