import React, { useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Edit, Trash } from 'react-feather';
import { Box } from '../Box/Box';
import { Button } from '../Button/Button';
import { List } from '../List/List';
import { Modal } from '../Modal/Modal';
import { ModalCenter } from '../Modal/ModalCenter';
import useUsers, { User } from '../../hooks/useUsers';
import { InputField, SelectField } from '../FormComponents/Input';
import useGroups from '../../hooks/useGroups';
import HasPermission from '../HasPermission/HasPermission';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { Group } from '../../types/Group';

interface UserFormProps {
  onSubmit: (user: User) => void;
  onResetPassword: (user: User) => void;
  onBlockAccess: (user: User) => void;
  user?: User;
  groups: Group[];
  edit: boolean;
  loading?: boolean;
}

export const UserForm = ({
  onSubmit, onResetPassword, onBlockAccess, user, groups, edit, loading = false,
}: UserFormProps) => {
  const { register, handleSubmit, errors } = useForm<User>({
    defaultValues: user,
  });

  const { t } = useTranslation();

  const groupItems = useMemo(() => ([
    ['', '---'],
    ...groups.map(({ group_id, name }) => [
      group_id, name,
    ]),
  ]), [groups]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-3 gap-4">
        <InputField<User>
          label={t('username')}
          type="email"
          register={register}
          name="cognito_username"
          required
          disabled={edit}
        >
          {errors.cognito_username && <p className="text-red-500">{t('invalid_value')}</p>}
        </InputField>

        <InputField<User>
          label={t('first_name')}
          type="text"
          register={register}
          name="first_name"
          required
        >
          {errors.first_name && <p className="text-red-500">{t('invalid_value')}</p>}
        </InputField>

        <InputField<User>
          label={t('last_name')}
          type="text"
          register={register}
          name="last_name"
          required
        >
          {errors.last_name && <p className="text-red-500">{t('invalid_value')}</p>}
        </InputField>

        <InputField
          label={t('title')}
          type="text"
          register={register}
          name="title"
        />

        <InputField
          label={t('phone_number')}
          type="text"
          register={register}
          name="phone_number"
        />

        <SelectField
          label={t('group')}
          register={register}
          name="group_id"
          items={groupItems as [string, string][]}
        >
          {errors.group_id && <p className="text-red-500">{t('invalid_value')}</p>}
        </SelectField>
      </div>

      <div className="flex">
        <Button type="submit" disabled={loading}>{loading ? t('loading') : t('submit')}</Button>
        <div className="flex-grow" />
        <Button type="button" className="ml-2" onClick={() => user && onBlockAccess(user)}>{ t('block_access') }</Button>
        <Button type="button" className="ml-2" onClick={() => user && onResetPassword(user)}>{ t('reset_password') }</Button>
      </div>
    </form>
  );
};

export interface ActionableUser extends User {
  group_name: string;
  select: (user: User) => void;
  delete: (user: User) => void;
}

const Users = () => {
  const [userEditModal, setUserEditModal] = useState<User | undefined>(undefined);
  const [userDeleteModal, setUserDeleteModal] = useState<User | undefined>(undefined);
  const {
    users, newUser, deleteUser, editUser, blockUser, resetUser,
  } = useUsers();

  const { groups } = useGroups();

  const { t } = useTranslation();

  return (
    <Box
      title={t('users')}
      headerChildren={(
        <Button onClick={() => setUserEditModal({} as User)}>
          +
          {t('add_user')}
        </Button>
      )}
      className="h-128 text-base"
    >
      {userEditModal !== undefined && groups.data && (
        <Modal>
          <ModalCenter
            title={
              userEditModal.cognito_username
                ? t('edit_user', {
                  first_name: userEditModal.first_name,
                  last_name: userEditModal.last_name,
                })
                : t('new_user')
            }
            closeModal={() => setUserEditModal(undefined)}
          >
            <UserForm
              loading={editUser.isLoading}
              edit={!!userEditModal.cognito_username}
              groups={groups.data}
              onSubmit={async (user) => {
                try {
                  if (!userEditModal.cognito_username) {
                    await newUser.mutateAsync(user);
                  } else {
                    await editUser.mutateAsync({
                      ...user,
                      cognito_username: userEditModal.cognito_username,
                    });
                  }
                } catch (e) {
                  // eslint-disable-next-line no-console
                  console.error(e);
                  // eslint-disable-next-line no-alert
                  alert('Oops, something went wrong.');
                }
                setUserEditModal(undefined);
              }}
              onResetPassword={async (user) => {
                if (userEditModal?.cognito_username) {
                  await resetUser(user);
                }
              }}
              onBlockAccess={async (user) => {
                if (userEditModal?.cognito_username) {
                  await blockUser(user);
                }
              }}
              user={userEditModal}
            />
          </ModalCenter>
        </Modal>
      )}

      {userDeleteModal !== undefined && (
        <Modal>
          <ModalCenter
            title={t('delete_username', {
              username: userDeleteModal.cognito_username,
            })}
            closeModal={() => setUserDeleteModal(undefined)}
          >
            <Button
              disabled={deleteUser.isLoading}
              onClick={async () => {
                await deleteUser.mutateAsync(userDeleteModal);
                setUserDeleteModal(undefined);
              }}
            >
              {deleteUser.isLoading ? t('loading') : t('confirm')}
            </Button>
          </ModalCenter>
        </Modal>
      )}

      {users.isLoading
        ? (<LoadingSpinner />)
        : users.data && groups.data && (
        <List
          data={
            users.data.map((user) => ({
              ...user,
              group_name: (
                groups.data
                && groups.data.find(({ group_id }: Group) => group_id === user.group_id)?.name
              ),
              name: `${user.first_name} ${user.last_name}`,
              select: () => setUserEditModal(user),
              delete: () => setUserDeleteModal(user),
            }))
          }
          columns={[
            ['name', t('name')],
            ['group_name', t('group')],
            ['select',
              <p>
                <span className="hidden xl:block">{t('edit')}</span>
                <Edit size={18} className="text-xs xl:hidden" />
              </p>,
            ],
            ['delete',
              <p>
                <span className="hidden xl:block">{t('delete')}</span>
                <Trash size={18} className="text-xs xl:hidden" />
              </p>,
            ],
          ]}
          columnsClass="grid-cols-4 2xl:grid-cols-4"
          showHeaders
          keyColumn="contact_id"
        />
        )}
    </Box>
  );
};

export default () => (
  <HasPermission permissions={['users']}>
    <Users />
  </HasPermission>
);
