export default {
  translation: {
    // General Translations
    submit: 'Submit',
    confirm: 'Confirm',
    edit: 'Edit',
    delete: 'Delete',
    resource: 'Room',
    status: 'Status',
    start: 'Start',
    end: 'End',
    cancel: 'Cancel',
    loading: 'Loading...',
    invalid_value: 'Invalid value',

    // No access
    no_portal_access_title: 'No portal access',
    no_portal_access_description: 'You do not have access to this portal. Please contact the Tribes team if you think this is an error.',

    // Login
    welcome_to_tribes: 'Welcome to Tribes',
    login_to_continue: 'Login to continue',
    login: 'Login',

    // Verify email
    you_need_to_verify_your_email: 'You need to verify your email address. Please enter the code sent to your email address.',
    verify_code: 'Verification code',
    verify_email: 'Verify',

    // Card names
    users: 'Users',
    groups: 'Groups',
    bookings: 'Bookings',
    book_meetingroom: 'Book Meetingroom',
    all_bookings: 'All Bookings',
    orders: 'Orders',
    invoices: 'Invoices',

    // General Info Card
    logout: 'Logout',
    number_of_users: 'Number of Users',
    number_of_groups: 'Number of Groups',
    your_bookings: 'Your Bookings',
    total_bookings: 'Total Bookings',

    // Users Card
    username: 'Email',
    first_name: 'First Name',
    last_name: 'Last Name',
    title: 'Title',
    phone_number: 'Phone number',
    point_of_contact_type: 'Point of Contact Type',
    not_a_point_of_contact: 'Not a point of contact',
    ceo: 'CEO',
    finance: 'Finance',
    operations: 'Operations',
    group: 'User group',
    add_user: 'Add user',
    new_user: 'New User',
    edit_user: 'Edit {{first_name}} {{last_name}}',
    delete_username: 'Are you sure you want to delete {{username}}?',
    reset_password: 'Reset password',
    block_access: 'Block access card',

    // Groups card
    name: 'Name',
    accessible_components: 'Accessible Components',
    add_group: 'Add group',
    edit_group: 'Edit {{group_name}}',
    new_group: 'New Group',
    delete_group: 'Are you sure you want to delete {{group_name}}?',

    // Bookings card
    make_a_booking: 'Make a booking',
    invite: 'Invite',
    company: 'Company',
    email: 'Email address',

    // All Bookings card
    user: 'User',

    // Orders card
    date: 'Date',
    items: 'Items',
    total: 'Total',

    // Invoices card
    download: 'Download',
    due_date: 'Due date',
    unpaid: 'Unpaid',
    paid: 'Paid',
  },
};
