import React from 'react';
import ReactDOM from 'react-dom';

import { debugContextDevtool } from 'react-context-devtool';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import reportWebVitals from './reportWebVitals';

import App from './App';

import './index.scss';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const widgetBaseURL = process.env.REACT_APP_WIDGET_URL || 'https://widget.booker25.com';
const widgetScript = document.createElement('script');
widgetScript.src = `${widgetBaseURL}/js/widget25.js`;
widgetScript.async = true;
document.head.appendChild(widgetScript);

dayjs.extend(localizedFormat);

const root = document.getElementById('root');
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  root,
);

debugContextDevtool(root);

reportWebVitals();
