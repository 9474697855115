import React, {
  HTMLAttributes, LegacyRef, ReactNode, useCallback, useEffect, useRef, useState,
} from 'react';

import './Box.scss';

type Props = HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
  headerChildren?: ReactNode;
  title?: string;
  className?: string;
};

export const Box = ({
  children,
  className,
  title,
  headerChildren,
}: Props) => {
  const [isRight, setIsRight] = useState(true);

  const boxRef = useRef<Element | null>(null);
  const checkIsLeft = useCallback(() => {
    if (boxRef.current !== null) {
      const { x, width, left } = boxRef.current?.getBoundingClientRect();
      setIsRight(x + width - left > (window.innerWidth - left) / 2);
    }
  }, [boxRef.current]);

  useEffect(() => {
    checkIsLeft();
  }, [boxRef.current]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    const onResize = () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => checkIsLeft(), 100);
    };

    window.addEventListener('resize', onResize);
    window.addEventListener('click', onResize);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      window.removeEventListener('resize', onResize);
      window.removeEventListener('click', onResize);
    };
  }, []);

  return (
    <div
      ref={boxRef as LegacyRef<HTMLDivElement>}
      className={[
        'bg-white p-4 md:p-7 lg:p-10 mb-5 break-inside box-box',
        isRight ? 'right-box' : 'left-box',
        className,
      ].join(' ')}
    >
      {title && (
        <div className="flex justify-between items-center border-b border-black pb-2 mb-7">
          <h2 className="text-xl md:text-2xl lg:text-4xl  font-header">
            {title}
          </h2>
          {headerChildren}
        </div>
      )}

      <div className="box">{children}</div>
    </div>
  );
};
