import React from 'react';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

interface StatisticProps {
  title: string;
  value: string | number;
  loading?: boolean;
}

const Statistic = ({ title, value, loading = false }: StatisticProps) => (
  <div className="mx-1">
    <div className="text-center">{ title }</div>
    {
      loading
        ? <LoadingSpinner />
        : <div className="text-center text-6xl mt-3">{ value }</div>
    }
  </div>
);

export default Statistic;
