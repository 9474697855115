/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { InputHTMLAttributes, ReactNode } from 'react';

import { RefReturn } from '../../types';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
interface InputFieldProps<T> extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  register: ({ required }: { required?: boolean }) => RefReturn;
  children?: ReactNode;
  name: keyof T;
}

export const InputField = <T extends unknown>({
  label,
  register,
  type,
  name,
  required,
  children,
  disabled,
}: InputFieldProps<T>) => {
  const isRadioOrCheckbox = type === 'radio' || type === 'checkbox';

  return (
    <div className="mb-5">
      {!isRadioOrCheckbox && (
        <label className="text-gray-800 mb-3 font-semibold">{label}</label>
      )}

      <div
        className={[
          'flex w-full mt-3',
          !isRadioOrCheckbox && 'flex-col',
          isRadioOrCheckbox && 'items-center',
        ].join(' ')}
      >
        <input ref={register({ required })} type={type} name={name as string} disabled={disabled} />

        {isRadioOrCheckbox === true && (
          <label className="text-gray-800 font-semibold">{label}</label>
        )}
        {children}
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
interface SelectFieldProps<T> extends InputHTMLAttributes<HTMLSelectElement> {
  label?: string;
  register: ({ required }: { required?: boolean }) => RefReturn;
  children?: ReactNode;
  name: keyof T;
  items: [string, string][];
}

export const SelectField = <T extends unknown> ({
  label,
  register,
  children,
  name,
  items,
  required,
}: SelectFieldProps<T>) => (
  <div className="mb-5">
    {label && (
    <label className="text-gray-800 mb-3 font-semibold">{label}</label>
    )}

    <div
      className={[
        'flex w-full mt-3',
        'flex-col',
      ].join(' ')}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <select ref={register({ required })} name={name as string}>
        {
            items.map(([value, text]) => (
              <option key={value} value={value}>{ text }</option>
            ))
          }
      </select>
      { children }
    </div>
  </div>
  );
