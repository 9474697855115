import React from 'react';
import { v4 } from 'uuid';
import dayjs from 'dayjs';

interface Props<T> {
  data: T;
  columns: [keyof T, string][];
  columnsClass?: string;
  rowClasses?: string;
  mdLabels?: boolean;
}

const currencyFormatter = Intl.NumberFormat(undefined, {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const ListItem = <T extends unknown>({
  data, columns, mdLabels = false, rowClasses = '', columnsClass = undefined,
}: Props<T>) => {
  const gridColumns = columnsClass ?? `grid-cols-${columns.length}`;
  return (
    <div
      className={`grid ${mdLabels ? `grid-cols-1 2xl:${gridColumns}` : gridColumns} border-b border-black pb-4 mb-4 ${rowClasses}`}
    >
      {
      columns.map(([key, label]) => {
        const value = data[key];
        let element;

        if (typeof value === 'string') {
          element = (
            <p key={v4()} className="self-center h-full align-top pr-1">
              {value}
            </p>
          );
        }

        if (typeof value === 'number') {
          element = (
            <p key={v4()} className="self-center h-full align-top pr-1">
              €&nbsp;
              {currencyFormatter.format(value)}
            </p>
          );
        }

        if (typeof value === 'boolean') {
          element = (
            <span
              key={v4()}
              className={`h-5 w-5 inline-block rounded-full bg-${value ? 'green' : 'red'}-500 self-center pr-1`}
            />
          );
        }

        if (typeof value === 'function') {
          element = (
            <div className="flex items-center pr-1">
              <button
                className="rounded-full bg-gray-300 mx-0.5 px-5 py-1"
                key={v4()}
                onClick={() => value()}
                type="button"
              >
                {label}
              </button>
            </div>
          );
        }

        if (value instanceof Date) {
          element = (
            <p key={v4()} className="self-center h-full align-top pr-1">
              {dayjs(value).format('DD-MM-YYYY')}
            </p>
          );
        }

        if (value === undefined) {
          element = (
            <span />
          );
        }

        return (
          <span className={`${mdLabels ? 'md-label' : ''}`}>
            <span className={`pl-2 align-middle ${mdLabels ? '2xl:hidden' : 'hidden'} label-for-type-${typeof value}`}>{label}</span>
            {element}
          </span>
        );
      })
    }
    </div>
  );
};
