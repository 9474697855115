/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { XCircle } from 'react-feather';
import { Box } from '../Box/Box';
import { Modal } from '../Modal/Modal';
import { ModalCenter } from '../Modal/ModalCenter';
import { Button } from '../Button/Button';
import './styles.scss';
import useBookings, { Invitee } from '../../hooks/useBookings';
import { List } from '../List/List';
import Paginator from '../Paginator/Paginator';
import HasPermission from '../HasPermission/HasPermission';
import BookerWidget from '../Booker/BookerWidget';
import { InviteeForm } from './InviteeForm';

export interface BookingsProps {
  business: string;
  pageId: string;
  contactId: string;
  apiOverride?: string;
  widgetUrlOverride?: string;
}

// The widget emits a toggle event when you pass a reservation id.
// This is undesirable since it will close the widget.
// With this lock we can ignore the first toggle event without triggering a new render.
let lock = false;

const Bookings = ({
  business, pageId, contactId, apiOverride,
}: BookingsProps) => {
  const [widgetState, setWidgetState] = useState({
    visible: false,
    reservation: undefined as (string | undefined),
    // closeLock: false,
  });
  const [inviteModal, setInviteModal] = useState<Invitee | undefined>(undefined);

  const {
    bookings, page, setPage, newInvitee,
  } = useBookings();
  const { t } = useTranslation();

  return (
    <>
      { widgetState.visible && (
        <Modal>
          <div className="modal__body h-5/6 bg-white rounded-3xl max-w-xl min-w-min widget">
            <BookerWidget
              business={business}
              page={pageId}
              contact={contactId}
              reservation={widgetState.reservation}
              api={apiOverride}
              className="rounded-3xl mb-5 w-full h-full"
              onLoad={() => { lock = Boolean(widgetState.reservation); }}
              onToggle={() => {
                if (!lock) {
                  setWidgetState(({
                    visible,
                    reservation,
                  }) => ({
                    visible: !visible,
                    reservation,
                  }));
                }
                lock = false;
              }}
              onReservation={(() => {
                setTimeout(() => {
                  setWidgetState(({ reservation }) => ({
                    visible: false,
                    reservation,
                  }));
                }, 1500);
              })}
            />
          </div>
          <div
            className="modal__background"
            onClick={() => {
              lock = false;
              setWidgetState(({ reservation }) => ({
                visible: false,
                reservation,
              }));
            }}
          />
        </Modal>
      )}

      <Box
        title={t('book_meetingroom')}
        className="h-1/2 text-base"
        headerChildren={(
          <Button
            onClick={() => {
              setWidgetState({
                visible: true,
                reservation: undefined,
              });
            }}
          >
            +
            {' '}
            {t('make_a_booking')}
          </Button>
      )}
      >
        {inviteModal !== undefined && (
          <Modal>
            <ModalCenter
              title={t('invite')}
              closeModal={() => setInviteModal(undefined)}
            >
              <InviteeForm
                loading={newInvitee.isLoading}
                onSubmit={async (invitee) => {
                  try {
                    await newInvitee.mutateAsync({
                      ...inviteModal,
                      ...invitee,
                    });
                  } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e);
                    // eslint-disable-next-line no-alert
                    alert('Oops, something went wrong.');
                  }
                  setInviteModal(undefined);
                }}
                invitee={inviteModal}
                edit={false}
              />
            </ModalCenter>
          </Modal>
        )}
        <List
          showHeaders
          loading={bookings.isLoading}
          data={(bookings.data?.items ?? []).map((booking) => ({
            ...booking,
            invite: dayjs(booking.start).isAfter(dayjs())
              ? () => {
                setInviteModal({ booking_id: booking.id } as Invitee);
              } : null,
            cancel: dayjs(booking.start).isAfter(dayjs().add(24, 'hours'))
              ? () => {
                lock = true;
                setWidgetState({
                  visible: true,
                  reservation: booking.id,
                });
              }
              : null,
            startFmt: dayjs(booking.start).format('LLL'),
            endFmt: dayjs(booking.end).format('LLL'),
          }))}
          columns={[
            ['resource', t('resource')],
            ['status', t('status')],
            ['startFmt', t('start')],
            ['endFmt', t('end')],
            // ['invite',
            //   <p>
            //     <span className="hidden xl:block">{t('invite')}</span>
            //     <UserPlus size={18} className="text-xs xl:hidden" />
            //   </p>,
            // ],
            ['cancel',
              <p>
                <span className="hidden xl:block">{t('cancel')}</span>
                <XCircle size={18} className="text-xs xl:hidden" />
              </p>,
            ],
          ]}
          columnsClass="grid-cols-5 2xl:grid-cols-5"
          // columnsClass="grid-cols-6 2xl:grid-cols-6"
          keyColumn="id"
        />
        <Paginator
          next={bookings.data?.next ?? null}
          previous={bookings.data?.prev ?? null}
          pages={bookings.data?.pages ?? 0}
          current_page={page}
          onPageClick={setPage}
        />
      </Box>
    </>
  );
};

export default (props: BookingsProps) => (
  <HasPermission permissions={['bookings']}>
    <Bookings
      {...props /* eslint-disable-line react/jsx-props-no-spreading */}
    />
  </HasPermission>
);
