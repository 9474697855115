import React from 'react';

interface ComponentComposerProps {
  children: React.ReactElement;
  components: React.ReactElement[];
}

/**
 * Composes multiple React elements into one without having big indents
 * @param components
 * @param children
 * @constructor
 */
const ComponentComposer = ({ components, children }: ComponentComposerProps) => (
  components.reduceRight((composedComponent, component) => (
    React.cloneElement(component, {
      children: composedComponent,
    })
  ), children)
);

export default ComponentComposer;
