import ReactDOM from 'react-dom';
import { ReactNode, useEffect, useMemo } from 'react';

const modalRoot = document.getElementById('portal');

type Props = {
  children: ReactNode;
};

export const Modal = ({ children }: Props) => {
  const el = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    modalRoot?.appendChild(el);

    return () => {
      modalRoot?.removeChild(el);
    };
  }, [el]);

  return ReactDOM.createPortal(children, el);
};
