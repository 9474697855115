/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ReactNode } from 'react';

import { Box } from '../Box/Box';
import { Close } from '../Icons/Close';

import './Modal.scss';

type Props = {
  children: ReactNode;
  title: string;
  closeModal: () => void;
  className?: string;
};

export const ModalCenter = ({
  children, title, closeModal, className = '',
}: Props) => (
  <>
    <Box
      title={title}
      className={`modal__body ${className}`}
      headerChildren={<Close onClick={() => closeModal()} />}
    >
      {children}
    </Box>

    <div
      className="modal__background"
      onClick={() => closeModal()}
    />
  </>
);
